import React, { Component } from 'react';

import styles from './Testimonial.module.css';
import Avatar from '@material-ui/core/Avatar';

export default class Map extends Component {
	render() {
		return (
			<div className={styles.container}>
				<div className={styles.innercontainer}>
					<Avatar alt="Jürgen" className={styles.avatar} src="./images/juergen.jpg" />
					"Seit Kindertagen verbrachte ich alle meine Ferien im Haus meiner Großeltern in Katalonien im
					Nordosten Spaniens. Als mich als Jugendlicher die Leidenschaft für die Berge ergriff, entdeckte ich
					dort das Weltklasseklettergebiet Siurana - Mandragora oder Hostia sind nur einige der
					atemberaubenden Routen. Meine Leidenschaft für die Berge und die spanische Weinkultur habe ich in
					meinem Laden Siurana vereint - hier biete ich persönliche Beratung und ausgesuchte hochwertige
					Produkte für jegliche Facetten des Bergsports sowie katalanische Weine und Olivenöl an."
					<p>Jürgen Christmann</p>
				</div>
			</div>
		);
	}
}
