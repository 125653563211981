import React, { Component } from 'react';
import axios from 'axios';
import styles from './ProductList.module.css';
import Grid from '@material-ui/core/Grid';
import Product from './Product';
import { Checkbox } from '@material-ui/core';



export default class ProductList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			products: {},
			categories: {},
			checkedCategories: [],

		};
	}
	componentDidMount() {
		axios
			.get(this.props.productURL)
			.then((response) => {
				this.setState({
					products: response.data.data
				});

			})
			.catch((error) => console.log(error));

		axios
			.get(this.props.categoryURL)
			.then((response) => {
				let cats = response.data.data;
				let checked = [];
				cats.forEach(element => {
					//element.checked = true;	
					//checked.push(element.id);
				});

				this.setState({
					categories: cats,
					checkedCategories: checked
				});
			})
			.catch((error) => console.log(error));
	}

	getProducts = () => {
		let data = [];
		for (var i = 0; i < this.state.products.length; i++) {
			let prod = this.state.products[i];
			
			if (prod.category == null || this.state.checkedCategories.includes(prod.category.id) || this.state.checkedCategories.length === 0) {
				let found = prod.image.data.thumbnails.filter(function (item) { return item.key === 'small'; });
				let cardImage = found[0].url;
				prod.cardImage = cardImage;

				data.push(
					<Grid item xs={12} sm={6} md={3} key={prod.id} >
						<Product product={prod} addToBasket={this.props.addToBasket} />
					</Grid>
				);
			}

		}
		return data;
	};

	handleCheckboxChange(category, event) {

		if (event.target.checked) {
			category.checked = true;
		} else {
			category.checked = false;
		}
		let cats = [];
		this.state.categories.forEach(element => {
			if (element.checked) {
				cats.push(element.id);
			}
		});


		this.setState({
			checkedCategories: cats
		});


	};

	getCategories = () => {
		var data = [];
		for (var i = 0; i < this.state.categories.length; i++) {
			let category = this.state.categories[i];


			data.push(
				<Grid item xs={6} sm={6} md={3} key={category.id}>
					<Checkbox value={category.id + ""} onChange={(event) => { this.handleCheckboxChange(category, event) }} checked={category.checked} />
					{category.name}
				</Grid>
			);
		}
		return data;
	};

	render() {
		return (
			<div>
				<div className={styles.center}>
					<div className={styles.categorybox}>
						<Grid container className={styles.categories}>
							{this.getCategories()}
						</Grid>
					</div>
				</div>

				<Grid container spacing={0}>
					{this.getProducts()}
				</Grid>
			</div>
		);
	}
}
