import React, { Component } from 'react';

import styles from './Map.module.css';
import { Grid } from '@material-ui/core';

export default class Map extends Component {
	render() {
		return (
			<div className={styles.container}>
				<div className={styles.innercontainer}>
					<Grid container className={styles.text}>
						<Grid item xs={12} sm={12} md={6}>
							<div className={styles.center}>
								<div>
								Ihr findet uns in Dornbirn Oberdorf - <span style={{fontWeight:'900'}}>Bergstraße 17</span>. Bei der Mohrenbrauerei Richtung
								Bödele sind wir nach zirka 800m auf der linken Seite. Gleich neben dem Spar Mathis in
								der ehemaligen Metzgerei Fontain
								</div>
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={6}>
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2700.0947452504624!2d9.750223215109942!3d47.41009310952603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479b6b4701937c53%3A0x6d95c8b55beabae7!2ssiurana%20bergsport%20%2B%20skikultur!5e0!3m2!1sde!2sat!4v1585259765038!5m2!1sde!2sat"
								width="100%"
								height="100%"
								frameBorder="0"
								aria-hidden="false"
								title="map"
								 
							/>
						</Grid>
					</Grid>
				</div>
			</div>
		);
	}
}
