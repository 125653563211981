import React, { Component } from "react";
import "../styles/App.css";
import "../styles/Menu.css";
import SectionSmall from "./SectionSmall";
import Section from "./Section";
import AppBar from "./AppBar";
import TopPadding from "./TopPadding";
import ProductList from "./ProductList";
import ProductText from "./ProductText";
import constants from "./constants";
import Testimonial from "./Testimonial";
import Map from "./Map";
import OpeningHours from "./OpeningHours";
import SectionCenter from "./SectionCenter";
import { Snackbar } from "@material-ui/core";

class App extends Component {
  constructor(props) {
    super(props);
    let cartLocalStorage = localStorage.getItem("cart");
    let cart = [];
    if (cartLocalStorage) {
      cart = JSON.parse(cartLocalStorage);
    } else {
      localStorage.setItem("cart", "[]");
    }
    this.state = {
      isLoading: true,
      basket: cart,
      snackOpen: false,
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: false,
    });
  }

  addToBasket = (element) => {
    let newBasket = this.state.basket;
    let prod = newBasket.filter((el) => {
      return el.id === element.id;
    });

    if (prod.length > 0) {
      prod[0].amount += element.amount;
    } else {
      newBasket.push(element);
    }

    localStorage.setItem("cart", JSON.stringify(newBasket));

    this.setState({
      basket: newBasket,
      snackOpen: true,
    });
  };

  getProductsFromBasket() {
    var data = [];
    for (var i = 0; i < this.state.basket.length; i++) {
      var prod = this.state.basket[i];
      data.push(prod.name);
    }
    return data;
  }
  render() {
    if (this.state.isLoading) {
      return <div> loading </div>;
    } else {
      return (
        <div>
          <AppBar active="0" cart={this.state.basket} />
          <SectionCenter id="top" bgcolor="white">
            <img
              src="/images/siurana.png"
              width="200px"
              height="130"
              alt="logo"
              className="imageShadow"
            />
          </SectionCenter>
          <SectionSmall bgcolor="white">
            <br></br><br></br>
          </SectionSmall>
          <SectionSmall bgcolor="#477680" padding="10px">
            <Testimonial />
          </SectionSmall>
          <Section id="sport" bgcolor="white">
            <TopPadding padding="30">
              <ProductText
                heading="Bergsport"
                description="Wir bieten Ausrüstung für alle  Facetten des Bergsports zu allen Jahreszeiten -  fürs Wandern, Klettern, Klettersteiggehen, Eisklettern, Bergsteigen, Skitouren und Skibergsteigen findest du bei uns Hardwear, Bekleidung und Schuhe. Das Berggehen verstehen wir als Kultur, die wir pflegen - darum gibt es bei uns nur selbst getestete und hochwertige Produkte!"
              />
              <ProductList
                productURL={constants.PRODUCT_URL}
                categoryURL={constants.CATEGORY_URL}
                addToBasket={this.addToBasket}
              />
            </TopPadding>
          </Section>
          <Section id="bike" bgcolor="#7F7A79">
            <TopPadding padding="30">
              <ProductText
                heading="Radsport"
                description="Bei uns findet ihr ausgewählte Italienische Rennräder und Gravelbikes des Traditionsherstellers Wilier und der Kultmarke Cinelli.
                Wilier, der ältester Radhersteller Italiens  (gegründet 1906 von Pietro Das Molin),  hat viel Erfahrung im Rennsport und stattet regelmässig Pro-Teams aus.
                Waren es früher Fiorenzo Magni, Claudio Corti oder der „Pirate“ Pantani, so fahren heute Pro Teams wie Astana bei der Tour de France, dem Giro d`Italia oder der Vuelta mit den Top Modellen von Wilier.
                Neben der neuesten Technik kommt bei Willier Rädern auch das schöne italienische Design nicht zu kurz.
                Cinelli - wer es kennt weiss wovon wir reden :o)  
                Design trifft auf Performance auf zwei Rädern. Neben schönen Rennrädern baute Cinelli schon Gravel Räder bevor es diesen Begriff überhaupt gab. "
              />
              <ProductList
                productURL={constants.PRODUCT_URL_BIKE}
                categoryURL={constants.CATEGORY_URL_BIKE}
                addToBasket={this.addToBasket}
              />
            </TopPadding>
          </Section>
          <Section id="gourmet" bgcolor="#72BDCC">
            <TopPadding padding="30">
              <ProductText
                heading="Feinkost"
                description="Alpine Feinkost bedeutet pura vida - Genuss und Berge, aber auch  Entspannung und Abenteuer. Siurana bietet eine Auswahl katalanischer Weine, Aperitifs und Süßweine sowie hochwertiges Olivenöl. Überdies gibt es bei uns den regionalen Gin und andere Destillate der Bergbrennerei Löwen aus dem Bregenzerwald. Wir kennen alle unsere Produzenten persönlich: Jaume, Eva und den Esel vom Weingut Ronadelles, Neus und Fernando von der Olivenölfinca Moli d’oli, Rainer von der Löwenbrennerei."
              />
              <ProductList
                productURL={constants.PRODUCT_URL_GOURMET}
                categoryURL={constants.CATEGORY_URL_GOURMET}
                addToBasket={this.addToBasket}
              />
            </TopPadding>
          </Section>
          <Section id="map" bgcolor="#F27E63">
            <Map />
          </Section>
          <Section id="contact" bgcolor="#454143" padding="70">
            <OpeningHours />
          </Section>
          <div className="footer">
            <a href="/impressum"> Impressum </a>
            <div>
              created by
              <a href="https://www.riedmann.rocks"> riedmann.rocks </a>
            </div>
            <div> ©Siurana </div>
          </div>
          <Snackbar
            open={this.state.snackOpen}
            autoHideDuration={2000}
            onClose={this.closeSnackbar}
            message="Produkt im Warenkorb!"
          />
        </div>
      );
    }
  }

  closeSnackbar = () => {
    this.setState({
      snackOpen: false,
    });
  };
}

export default App;
