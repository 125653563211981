import React from 'react';
import ReactDOM from 'react-dom';
import { Route,  BrowserRouter as Router } from 'react-router-dom';
import App from './components/App';
import Impressum from './components/Impressum';
import Cart from './components/Cart';
import Success from './components/Success';

const routing = (
  <Router>
    <div>
      <Route exact  path="/" component={App} />
      <Route exact path="/cart" component={Cart} />
      <Route exact path="/success" component={Success} />
      <Route path="/impressum" component={Impressum} />
      
    </div>
  </Router>
)
ReactDOM.render(routing, document.getElementById('root'))