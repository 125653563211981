import React, { Component } from 'react';


export default class ProductText extends Component {

	render() {
		return (
			<div style={{textAlign:'center',padding:'30px',}}>
               <h1>{this.props.heading}</h1>
               {this.props.description}
			</div>
		);
	}
}
