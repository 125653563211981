import React, { Component } from 'react';
import { FaBars } from 'react-icons/fa';
import { FaTimes } from 'react-icons/fa';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Link } from 'react-router-dom';
import styles from './AppBar.module.css';

export default class AppBar extends Component {
	constructor(props) {
		super(props);

		this.style = {
			trans: 'navbar navbar-transparent',
			black: 'navbar navbar-color '
		};

		this.state = {
			activeStyle: this.style.trans,
			scrollSize: 100,
			isMobile: window.innerWidth <= 800,
			menuClass: 'collapse navbar-collapse'
		};
	}
	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll = (event) => {
		let el = document.scrollingElement || document.documentElement;
		let scrollTop = el.scrollTop;
		//let scrollTop = event.srcElement.scrollingElement.scrollTop;

		if (scrollTop > this.state.scrollSize) {
			this.setState({
				activeStyle: this.style.black
			});
		} else {
			this.setState({
				activeStyle: this.style.trans
			});
		}
	};

	onNavClick = (event) => {
		event.preventDefault();
		let href = event.currentTarget.getAttribute('href');
		let el = document.getElementById(href);

		//el.scrollIntoView({ block: "start", behavior: "smooth" });
		el.scrollIntoView();
		if (this.state.isMobile) {
			this.setState({
				menuClass: 'collapse navbar-collapse open-drawer'
			});
		}
	};

	closeMenu = () => {
		this.setState({
			menuClass: 'collapse navbar-collapse open-drawer',
			isMobile: true
		});
	};

	toggleMenu = () => {
		this.setState({
			menuClass: 'open-drawer-active open-drawer',
			isMobile: true
		});
	};

	getDesktopCart() {
		if (!this.state.isMobile) {
			return this.getCart();
		}
	}

	getMobileCart() {
		if (this.state.isMobile) {
			return this.getCart();
		}
	}

	getCart() {
		return (
			<Link
				to={{
					pathname: '/cart',
					state: { cart: this.props.cart }
				}}
			>
				<ShoppingCartIcon />
				<span style={{ fontSize: '10px' }}>{this.getAmountOfArticles()}</span>
			</Link>
		);
	}

	getAmountOfArticles(){
		let amount = 0;
		this.props.cart.forEach(element => {
			amount += element.amount
		});
		return amount;
	}

	render() {
		return (
			<div className="header">
				<nav className={this.state.activeStyle}>
					<div>
						<div className="navbar-header">
							<div className="menu-icon" onClick={this.toggleMenu}>
								<div className="menu-open-icon">
									<FaBars />
								</div>

								<div className="mobile-menu-heading">
									<h3 style={{ display: 'inline', position:'relative',top:'10px' }}>Siurana</h3>
									<div style={{ display: 'inline', position:'relative',top:'14px', paddingLeft:'25px'}}>{this.getMobileCart()}</div>
								</div>
							</div>
							<div className={this.state.menuClass} id="custom-collapse">
								<div className="closeicon" onClick={this.closeMenu}>
									<FaTimes />
								</div>
								<div>
									<ul>
										<li>
											<a href="top" onClick={this.onNavClick} className="section-scroll">
												Home
											</a>
										</li>
										<li>
											<a href="sport" onClick={this.onNavClick} className="section-scroll">
												Bergsport
											</a>
										</li>
										<li>
											<a href="bike" onClick={this.onNavClick} className="section-scroll">
												Radsport
											</a>
										</li>
										<li>
											<a href="gourmet" onClick={this.onNavClick} className="section-scroll">
												Alpine-Feinkost
											</a>
										</li>
									
										<li>
											<a href="map" onClick={this.onNavClick} className="section-scroll">
												Anreise
											</a>
										</li>
										<li>
											<a href="contact" onClick={this.onNavClick} className="section-scroll">
												Öffnungszeiten & Kontakt
											</a>
										</li>
									</ul>
								</div>
								<div style={{ textAlign: 'right' }}>
									<ul>
										<li style={{ position: 'relative', top: '-7px' }}>
											<img className={styles.shadow} src="/images/siurana_small.png" alt="logo" width="70px"></img>
										</li>
										<li style={{ position: 'relative', top: '-7px' }} className="cart">{this.getDesktopCart()}</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</nav>
			</div>
		);
	}
}
