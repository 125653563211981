import React, { useState, useEffect } from 'react';

import styles from './Product.module.css';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import WOW from "wowjs";

export default function Product(props) {
	const [ amount, setAmount ] = useState(1);
	const [ dialogOpen, setDialogOpen ] = useState(false);
	

	useEffect(() => {
		document.addEventListener('keyup', (e) => {
			if (e.keyCode === 27) handleCloseDialog();
		});
		const wow = new WOW.WOW({
			live: false
		});
		wow.init();
		
		
		
		return document.addEventListener('keyup', (e) => {
			if (e.keyCode === 27) handleCloseDialog();
		});
		
	}, []);

	function handleAddElement() {
		setAmount(amount + 1);
	}

	function handleRemoveElement() {
		setAmount(amount - 1);
	}

	function handleProductClick() {
		setDialogOpen(true);
	}

	function handleCloseDialog() {
		setDialogOpen(false);
	}
	return (
		<div className={`${styles.card} wow bounceInUp`}>
			<div className={styles.center} onClick={handleProductClick}>
				<div className={styles.header}>
					<div className={styles.cardHeading}>{props.product.name}</div>
					<div className={styles.cardCategory}>{props.product.category.name}</div>
				</div>

				<div className={styles.imageContainer}>
					<img width="150px" height="150px" src={props.product.cardImage} className={styles.image} alt="produktbild" />
				</div>

				<div className={`${styles.cardDescription} ${styles.margin}`}>{props.product.price.toFixed(2)} €</div>
			</div>
			<div
				className={styles.bottom}
				onClick={() => {
					props.addToBasket(props.product);
				}}
			>
				
			</div>
			<div className={styles.bottom}>
				<div className={styles.changeAmount}>
					<div className={styles.button} onClick={handleRemoveElement}>
						<RemoveIcon />
					</div>
					<div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
						<input className={styles.input} value={amount} disabled />
					</div>
					<div className={styles.button} onClick={handleAddElement}>
						<AddIcon />
					</div>
				</div>
				<div
					className={styles.sendToCart}
					onClick={() => {
						let obj = props.product;
						obj.amount = amount;
						props.addToBasket(obj);
					}}
				>
					Add to Cart <AddShoppingCartIcon style={{ position: 'relative', top: '5px' }} fontSize="small" />
				</div>
			</div>
			<Dialog aria-labelledby="customized-dialog-title" open={dialogOpen}>
				<CloseIcon className={styles.closeIcon} onClick={handleCloseDialog} />
				<div className={`${styles.center} ${styles.dialog}`}>
					<div className={styles.header}>
						<div className={styles.cardHeading}>{props.product.name}</div>
						<div className={styles.cardCategory}>{props.product.category.name}</div>
					</div>

					<div>
						<img
							src={props.product.image.data.full_url}
							className={styles.imageInDialog}
							alt="produktbild"
						/>
					</div>
					<div className={styles.cardDescription}>{props.product.description}</div>

					<div className={`${styles.cardDescription} ${styles.margin}`}>
						{props.product.price.toFixed(2)} €
					</div>
				</div>
			</Dialog>
		</div>
	);
}
