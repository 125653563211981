import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

export default class Success extends Component {
	constructor(props) {
		super(props);
		localStorage.setItem('cart',[]);
		
	}

	render() {
		return (
			<div style={{ textAlign: 'center' }}>
				<h3>Bezahlung/Bestellung erfolgreich!</h3>
				Wir melden uns in Küze bei Dir!
				<div>
					<Button>
						<Link to={{ pathname: '/' }}>Zurück zur Homepage</Link>
					</Button>
				</div>
			</div>
		);
	}
}
