import React, { Component } from 'react';
import styled from 'styled-components';

const CenterContainer = styled.div`
  display: flex;
  justify-content:center;
  align-items:center;
  padding-top:80px;
  box-shadow:0px 16px 16px 0px rgba(0,0,0,0.2);
`;

export default class SectionCenter extends Component {
	constructor(props) {
		super(props);
		this.divStyle = {
			backgroundColor: this.props.bgcolor,
			padding: this.props.padding,
		};
		
	}

	render() {
		return (
			<CenterContainer id={this.props.id} style={this.divStyle}>
				{this.props.children}
			</CenterContainer>
		);
	}
}
