import React, { Component } from 'react';

export default class SectionSmall extends Component {
	constructor(props) {
		super(props);
		this.divStyle = {
			backgroundColor: this.props.bgcolor,
			padding: this.props.padding
		};
		if (this.props.bgimage) {
			this.divStyle.backgroundImage = `url(${this.props.bgimage})`;
			this.divStyle.backgroundPosition = 'center';
			this.divStyle.backgroundRepeat = 'no-repeat';
			this.divStyle.backgroundSize = 'cover';
		}
	}

	render() {
		return (
			<div id={this.props.id} style={this.divStyle}>
				<div className={this.props.classname}>{this.props.children}</div>
                
			</div>
		);
	}
}
