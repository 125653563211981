import React, { Component } from 'react';
import styles from './Cart.module.css';
import { Link } from 'react-router-dom';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import axios from 'axios';
import constants from './constants';

import TextField from '@material-ui/core/TextField';

import { Grid } from '@material-ui/core';

export default class Cart extends Component {
	constructor(props) {
		super(props);

		this.state = {
			cart: JSON.parse(localStorage.getItem('cart')),
			message: '',
			open: false,
			contact: {}
		};
	}

	deleteProduct(index) {
		const copy = this.state.cart;
		copy.splice(index, 1);
		localStorage.setItem('cart', JSON.stringify(copy));
		this.setState({
			cart: copy
		});
	}

	getPrice = (product) => {
		return product.price * product.amount;
	};

	getCartList = () => {
		let data = [];
		let sum = 0;

		for (let i = 0; i < this.state.cart.length; i++) {
			let prod = this.state.cart[i];

			sum += prod.price * prod.amount;
			data.push(
				<tr key={i}>
					<td>{prod.name}</td>
					<td style={{ textAlign: 'center' }}>{prod.amount}</td>
					<td className={styles.money}>{prod.price.toFixed(2)} €</td>
					<td className={styles.money}>{this.getPrice(prod).toFixed(2)} €</td>
					<td className={styles.delete}>
						<DeleteOutlineIcon
							onClick={(event) => {
								this.deleteProduct(i);
							}}
						/>
					</td>
				</tr>
			);
		}

		data.push(
			<tr className={styles.sum} key={100000}>
				<td>Summe</td>
				<td />
				<td />
				<td className={styles.money}>{sum.toFixed(2)} €</td>
				<td />
			</tr>
		);
		return data;
	};

	getProductsForPayment() {
		let newArr = [];
		for (let i = 0; i < this.state.cart.length; i++) {
			let newProd = {};
			let prod = this.state.cart[i];

			newProd.name = prod.name;
			newProd.description = prod.description;
			newProd.amount = prod.price * 100 ;
			newProd.currency = 'EUR';
			newProd.quantity = prod.amount;
			if (newProd.amount === 0) {
				newProd.amount = 1;
			}
			newArr.push(newProd);
		}
		return newArr;
		//return [{"name":"prod1e", "description":"testinger", "amount":5000,"currency":"EUR", "quantity":2},{"name":"prod2", "description":"testinger2", "amount":6000,"currency":"EUR", "quantity":3}];
	}

	getProductsAsString() {
		let data = '';
		for (let i = 0; i < this.state.cart.length; i++) {
			let prod = this.state.cart[i];
			data += 'name: ' + prod.name + ' *** price:' + prod.price + ' *** amount: ' + prod.amount + "\n";
		}
		return data;
	}

	checkout = () => {
		let that = this;
		let products = this.getProductsForPayment();
		axios
			.post(constants.CHECKOUT_API_URL, products, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					Accept: 'application/json'
				}
			})
			.then((response) => {
				let id = response.data.id;
				that.makePayment(id);
			})
			.catch((error) => {
				//this.showMessage('Es hat etwas nicht geklappt!');
				this.setState({
					message: 'Some error occured!' + error
				});
				console.log(error);
			});
	};

	async makePayment(id) {
		const stripe = window.Stripe('pk_live_Szyo3lLHOayhId9KVDEAM33S00nb6CrRmi');

		stripe
			.redirectToCheckout({
				// Make the id field from the Checkout Session creation API response
				// available to this file, so you can provide it as parameter here
				// instead of the {{CHECKOUT_SESSION_ID}} placeholder.
				sessionId: id
			})
			.then(function(result) {
				this.setState({
					message: result.error.message
				});
			});
	}

	handleClickOpen = () => {
		this.setState({
			open: true
		});
	};

	handleCheckout = (checkout) => {
		let contact = this.getFormData();
		if (!this.isContactValid(contact)) {
			this.showMessage('Geben Sie bitte Name, Telefon und Email an!');
			return;
		}
		this.setState({
			open: true,
			contact: contact
		});

		this.saveOrder(contact, checkout);
	};

	saveOrder(contact, checkout) {
		contact.order = this.getProductsAsString();
		axios
			.post(constants.SAVE_ORDER, contact, {
				headers: {
					'Content-Type': 'application/json'
				}
			})
			.then((response) => {
				if (checkout !== 'ok') {
					this.checkout();
				} else {
					window.open('/success', '_self');
				}
			})
			.catch((error) => {
				//this.showMessage('Es hat etwas nicht geklappt!');
				this.setState({
					message: 'Some error occured!' + error
				});
				console.log(error);
			});
	}

	showMessage = (message) => {
		this.setState({
			message: message
		});
	};

	handleCheckoutContact = () => {
		this.handleCheckout('ok');
	};

	handleCloseCancel = () => {
		this.setState({
			open: false,
			message: ''
		});
	};

	isContactValid(contact) {
		if (contact.email.length === 0 || contact.name.length === 0 || contact.phone.length === 0) {
			return false;
		} else {
			return true;
		}
	}

	getFormData() {
		let contact = {};
		contact.email = document.getElementById('email').value;
		contact.name = document.getElementById('name').value;
		contact.phone = document.getElementById('phone').value;
		contact.street = document.getElementById('street').value;
		contact.city = document.getElementById('city').value;
		return contact;
	}

	render() {
		if (this.state.cart.length === 0) {
			return (
				<div>
					<div className={styles.header}>
						<Link to={{ pathname: '/' }}>
							<ArrowBackIcon className={styles.icon} /> Home
						</Link>
					</div>
					<div className={styles.container}>
						<div className={styles.center}>
							<h3>Der Warenkorb ist leer!</h3>
						</div>
					</div>
				</div>
			);
		} else
			return (
				<div>
					
					<div className={styles.header}>
						<Link to={{ pathname: '/' }}>
							<ArrowBackIcon className={styles.icon} /> Home
						</Link>
					</div>

					<div className={styles.container}>
						<div className={styles.center}>
							<div className={this.state.open ? styles.dialogOff : styles.dialogOn}>
								<div>
									<h3>Warenkorb</h3>
								</div>
								<div className={styles.error}>{this.state.message}</div>
								<table className={styles.sumTable}>
									<thead style={{ color: 'lightgray' }}>
										<tr>
											<td>Produkt</td>
											<td>Menge</td>
											<td className={styles.money}>EP</td>
											<td className={styles.money}>Gesamt</td>
											<td></td>
										</tr>
									</thead>
									<tbody>{this.getCartList()}</tbody>
								</table>
								<div style={{ width: '100%', textAlign: 'center' }}>
									<Button
										onClick={this.handleClickOpen}
										className={styles.button}
										variant="contained"
										color="primary"
									>
										Weiter...
									</Button>
								</div>
							</div>
							<div className={this.state.open ? styles.dialogOn : styles.dialogOff}>
								<div className={styles.center}>
									<div className={styles.boxSize}>
										<div className={styles.error}>{this.state.message}</div>
										<div>
											<div>
												Bitte gib hier Deine Daten ein. Wir melden uns dann in Kürze bei Dir.
												Bezahlen kannst Du entweder direkt mit Kreditkarte oder nach
												Kontaktaufnahme mit uns!
											</div>

											<TextField
												autoFocus
												margin="dense"
												id="name"
												label="Name"
												type="text"
												fullWidth
											/>
											<TextField
												margin="dense"
												id="email"
												label="Email Address"
												type="email"
												fullWidth
											/>
											<TextField
												margin="dense"
												id="phone"
												label="Telefon"
												type="text"
												fullWidth
											/>
											<TextField
												margin="dense"
												id="street"
												label="Adresse"
												type="text"
												fullWidth
											/>
											<TextField margin="dense" id="city" label="Ort" type="text" fullWidth />
										</div>
										<div style={{ textAlign: 'center' }}>
											<Grid container direction="row" justify="center" alignItems="center">
												<Grid item xs={12} sm={12} md={4} key="1">
													<Button
														className={styles.button}
														onClick={this.handleCheckout}
														variant="contained"
														color="primary"
													>
														Direkt bezahlen&nbsp; <CreditCardIcon />
													</Button>
												</Grid>
												<Grid item xs={12} sm={12} md={4} key="2">
													<Button
														className={styles.button}
														variant="contained"
														color="primary"
														onClick={this.handleCheckoutContact}
													>
														Kontaktaufnahme
													</Button>
												</Grid>
												<Grid item xs={12} sm={12} md={4} key="3">
													<Button
														className={styles.button}
														variant="contained"
														color="secondary"
														onClick={this.handleCloseCancel}
													>
														Abbrechen
													</Button>
												</Grid>
											</Grid>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
	}
}
