var base = "https://api.riedmann.rocks/siurana/";
var api = "https://api.riedmann.rocks/admin/api/";
module.exports ={
    
    BASE_URL: base ,
    PRODUCT_URL: base + "items/products?fields=*.*.*&filter[issport]=1&status=published",
    CATEGORY_URL: base + "items/category?fields=*.*.*&filter[issport]=1&status=published",
    PRODUCT_URL_GOURMET: base + "items/products?fields=*.*.*&filter[issport]=0&status=published",
    CATEGORY_URL_GOURMET: base + "items/category?fields=*.*.*&filter[issport]=0&status=published",
    PRODUCT_URL_BIKE: base + "items/products?fields=*.*.*&filter[issport]=2&status=draft",
    CATEGORY_URL_BIKE: base + "items/category?fields=*.*.*&filter[issport]=2&status=draft",
    INSTA_URL: "https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url&access_token=",
    INSTA_TOKEN: api + "siuranaGetToken.php",
    CHECKOUT_API_URL : api + "siurana_stripe.php",
    SAVE_ORDER: base + "items/orders"
    
    
}