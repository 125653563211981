import React, { Component } from 'react';
import styles from './OpeningHours.module.css';

export default class OpeningHours extends Component {
	render() {
		return (
			<div className={styles.container}>
				<div>
					<h4>Öffnunszeiten</h4>
					<ul className={styles.list}>
						<li>Di 9-12 14-18</li>
						<li>Mi 9-12 14-18</li>
						<li>Do 9-12 14-20</li>
						<li>Fr 9-12 14-18</li>
						<li>Sa 9-13</li>
					</ul>
					<div>
						<span style={{ fontWeight: '800' }}>Telefon: </span> +43 5572 202 201
					</div>
					<div>
						<span style={{ fontWeight: '800' }}>E-Mail:</span>
						<a href="mailto"> shop@siurana.at</a>
					</div>
				</div>
			</div>
		);
	}
}
